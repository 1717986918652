<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="page-container block-container">
      <div class="column left-block large-block">
        <h1>Dedicated servers</h1>
        <h2>Dedicated Servers</h2>
        <p>Dedicated servers bestaan uit een eigen fysieke server, zodat u daarvan de volledige capaciteit kunt benutten. Het is uw eigen server, ‘dedicated’ staat in dit geval voor ‘toegewezen’. We wijzen de fysieke server aan u toe en zorgen er daarmee voor dat u optimaal gebruik kunt maken van zowel de capaciteit als de mogelijkheden en specificaties die er beschikbaar zijn.</p>
        <p>Met een dedicated server maakt u optimaal gebruik van de mogelijkheden die er beschikbaar zijn, omdat u geen last heeft van eventuele andere websites op de server waarover u het beheer niet voert.</p>
        <p><i>Tip: u kunt een dedicated server uiteraard wel gebruiken om zelf meerdere websites of applicaties te hosten. U voert daarover zelf het beheer, zodat u zich heeft op bijvoorbeeld de bandbreedte die zij vragen of de software die ze draaien.</i></p>
        <p>De dedicated server is op die manier een goede keuze indien u gebruik wilt maken van een veilige en betrouwbare back-upserver voor uw data of als u meerdere IP-adressen nodig heeft. Dit kan uitkomst bieden indien de dagelijkse data-overdracht te excessief wordt en indien u veel opslagcapaciteit nodig heeft. Met name voor de hosting van specifieke applicaties wordt er vaak gebruik gemaakt van een dedicated server.</p>
        <h3>Wat is een dedicated server?</h3>
        <img
          class="framed-image"
          src="../../assets/images/cloudservers.png"
          height="184"
          width="300"
          title="dedicated server"
          alt="dedicated server"
        >
        <p>We krijgen van klanten regelmatig de vraag: “wat is een dedicated server?” Het antwoord daarop kunnen we zowel eenvoudig als op een meer complexe manier geven. Technisch gezien is het een professionele oplossing voor specifieke applicaties of websites die veel opslagcapaciteit nodig hebben. Er zijn allerlei toepassingen mogelijk, waarbij wij ervoor zorgen dat de techniek goed in orde is.</p>
        <p>Het eenvoudige antwoord op de vraag “wat is een dedicated server?” spreekt over het algemeen meer klanten aan. Het is een eigen fysieke server (in tegenstelling tot een <router-link to="/cloud-server-hosting-vps-hosting/vps-1/">Virtual Private Server</router-link>. U zorgt er daarmee voor dat u geen last heeft van andere websites of applicaties op de server, waar u zelf niet het beheer over kunt voeren. In plaats daarvan staat er een eigen dedicated server tot uw beschikking. We zorgen er daarmee voor dat u kunt rekenen op de maximale betrouwbaarheid en beschikbaarheid, zonder dat u daarvoor afhankelijk bent van anderen.</p>
        <h3>Uw dedicated server bij Hosting on Demand</h3>
        <p>Een dedicated server bij Hosting on Demand biedt een aantal belangrijke voordelen:</p>
        <ul>
          <li>Gebruik van A-merk serverapparatuur</li>
          <li>Uitstekende support bij uw dedicated server</li>
          <li>Professionele mogelijkheden voor back-up</li>
          <li>Diverse capaciteiten voor een dedicated server mogelijk</li>
        </ul>
        <p>We zorgen er met de dedicated server voor dat u optimaal gebruik kunt maken van de online mogelijkheden die er beschikbaar zijn, bijvoorbeeld voor uw website of applicatie. Meer weten? Neem contact met ons op, we horen graag van u.</p>
      </div>
      <div class="column right-block small-block">
        <div class="green-block">
          <h3>Uw eigen server bij Hosting on Demand</h3>
          <ul>
            <li>Maximale performance</li>
            <li>Geen afhankelijkheid van derden</li>
            <li>Eigen beheer mogelijk</li>
            <li>Als u er niet uitkomt, helpen wij verder</li>
          </ul>
        </div>
        <div class="green-block">
          <h3>Veel gestelde vragen over dedicated-servers</h3>
          <ExpansionPanels
            :panels="questions.map(q => q.title)"
          >
            <template
              v-for="(question, index) in questions"
              :key="index"
              v-slot:[questionSlotName(index)]
            >
              <div v-html="question.content"></div>
            </template>
          </ExpansionPanels>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ExpansionPanels from '@/components/ExpansionPanels.vue';

export default {
  name: 'DedicatedServers',
  components: {
    Breadcrumbs,
    ExpansionPanels,
  },
  setup() {
    const data = inject('data');
    const questions = data.staticData.questions.filter(q => q.tags.split(' ').includes('dedicated-servers'));

    function questionSlotName(index) {
      return `content-text-${index}`;
    }

    return {
      // store
      data,

      // static data
      questions,

      // methods
      questionSlotName,
    };
  },
}
</script>